/**
 *
 *
 *
 */

import { Suspense, lazy } from 'react'
import AppNavigationWrapper from '../components/common/app-navigation-wrapper'
import { Route, Routes } from 'react-router-dom'
import Loader from '../components/common/loader'
import Users from './users'


const Overview = lazy(() => import('./overview'))
const MusicLibrary = lazy(() => import('./music-library'))
const Media = lazy(() => import('./media'))
const Campaigns = lazy(() => import('./campaigns'))
const Channels = lazy(() => import('./channels'))
const Players = lazy(() => import('./players'))
const Locations = lazy(() => import('./locations'))
const Accounts = lazy(() => import('./accounts'))
const MyProfile = lazy(() => import('./my-profile'))

export interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = (props) => {
  return (
    <AppNavigationWrapper>
      <Suspense fallback={<Loader open={true} />}>
        <Routes>
          <Route path="music-library" element={<MusicLibrary />} />
          <Route path="media" element={<Media />} />
          <Route path="campaigns" element={<Campaigns />} />
          <Route path="channels/*" element={<Channels />} />
          <Route path="players" element={<Players />} />
          <Route path="locations" element={<Locations />} />
          <Route path="accounts" element={<Accounts />} />``
          <Route path="users" element={<Users />} />
          <Route path="my-profile" element={<MyProfile />} />

          {/* Fallback / Homepage */}
          <Route path="*" element={<Overview />} />
        </Routes>
      </Suspense>
    </AppNavigationWrapper>
  )
}
export default Dashboard
