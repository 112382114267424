/**
 *
 *
 */

import { useCallback, useEffect, useState } from 'react'

import { Token, User } from '../../types/user'
import { jwtDecode } from 'jwt-decode'
import { apiBaseUrl } from '../config/api'
import useSessionStorage from './use-session-storage'
import { tokenHeaderName, tokenKey } from '../config/authentication'
import { Account } from '../../types/account'

/**
 *
 */
export const useAuthentication = () => {
  const { save, load, remove } = useSessionStorage()

  const [token, setToken] = useState<string | undefined>(load(tokenKey))
  const [accountId, setAccountId] = useState<Account['accountId'] | undefined>(
    undefined
  )
  const [accounts, setAccounts] = useState<Account[] | undefined>(undefined)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Login a user
   * @param email
   * @param password
   * @returns boolean if login succeeded or not
   */
  const login = useCallback(
    async (email: string, password: string, accountId?: number) => {
      try {
        if (!email || !password) {
          return false
        }

        setLoading(true)
        setAccounts(undefined)
        setAccountId(undefined)

        const response = await fetch(`${apiBaseUrl}/authentication/login`, {
          body: JSON.stringify({ email: email, password: password, accountId }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })

        const jsonbody = await response.json<
          { token: string } | { accounts: Account[] }
        >()

        if (response.status === 400 && 'accounts' in jsonbody) {
          setAccounts(jsonbody.accounts)
          setLoading(false)
          return true
        }

        if ('token' in jsonbody && jsonbody?.token) {
          setToken(jsonbody.token)
          save(tokenKey, jsonbody.token)
          try {
            const payload = jwtDecode<Token>(jsonbody.token)
            setUser({
              userId: payload.sub,
              email: '', // payload.email || '',
              name: payload.name || '',
              admin: payload.admin || false,
              lastPasswordUpdate: payload.lastPasswordUpdate || 0,
              createdAt: payload.createdAt || 0,
            })
            if (payload.accountId) {
              setAccountId(payload.accountId)
            }
          } catch (err) {
            console.error(err)
          }
          setLoading(false)
          return true
        }

        setLoading(false)
        return false
      } catch (err) {
        console.error(err)
        setLoading(false)
        return false
      }
    },
    [save]
  )

  /**
   * User logout
   * @returns boolean if logout succeeded or not
   */
  const logout = useCallback(async () => {
    setToken(undefined)
    setUser(undefined)
    setAccountId(undefined)
    setAccounts(undefined)
    remove(tokenKey)
    return true
  }, [remove])

  /**
   * Checks if the current token is valid
   * @param _token
   * @returns boolean if token is valid or not
   */
  const isTokenValid = useCallback(
    async (_token?: string) => {
      if (_token) {
        setLoading(true)
        try {
          // request
          const response = await fetch(
            apiBaseUrl + '/authentication/check-token',
            {
              method: 'HEAD',
              headers: {
                [tokenHeaderName]: _token,
              },
            }
          )

          // non 200 response
          if (!response.ok) {
            throw new Error('token is not not valid')
          }

          try {
            const payload = jwtDecode<Token>(_token)
            setUser({
              userId: payload.sub,
              email: payload.email || '',
              name: payload.name || '',
              admin: payload.admin || false,
              lastPasswordUpdate: payload.lastPasswordUpdate || 0,
              createdAt: payload.createdAt || 0,
            })
            if (payload.accountId) {
              setAccountId(payload.accountId)
            }
          } catch (err) {
            console.error(err)
          }

          // done
          setLoading(false)
          return true
        } catch (err) {
          console.error(err)

          // logout
          await logout()

          // done
          setLoading(false)
          return false
        }
      }
      return false
    },
    [logout]
  )

  /**
   * Switch account
   * @param newAccountId
   * @returns boolean if switch succeeded or not
   */
  const switchAccount = useCallback(async (newAccountId?: number) => {
    if (!token || !newAccountId) {
      return false;
    }

    try {
      setLoading(true);

      const response = await fetch(`${apiBaseUrl}/authentication/switch-account`, {
        body: JSON.stringify({ newAccountId }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          [tokenHeaderName]: token,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to switch account');
      }

      const data = await response.json<{ token: string }>();

      if( data.token ) {
        setToken(data.token);
        setAccountId(newAccountId);
      } else {
        throw new Error('Failed to switch account');
      }

      setLoading(false);
      return true;
    } catch (error) {
      console.error('Error switching account:', error);
      setLoading(false);
      return false;
    }
  }, [token])


  /**
   * autosave the token if changed
   */
  useEffect(() => {
    if (token) {
      isTokenValid(token)
    }
  }, [token, isTokenValid])

  return {
    token,
    accounts,
    accountId,
    setAccountId,
    user,
    loading,
    isTokenValid,
    login,
    logout,
    switchAccount,
  }
}
export default useAuthentication
