/**
 *
 *
 *
 */

import { useContext } from 'react'
import { UserContext } from '../../contexts/user-context'

export interface NeedsAuthenticationProps {
  unAuthenticatedElement?: React.ReactElement
  children?: React.ReactNode
}

/**
 * will show unauthenticatedElement if user is not logged in
 * @returns it's children, the unauthenticatedElement or null
 */
export const NeedsAuthentication: React.FC<NeedsAuthenticationProps> = (
  props
) => {
  const { unAuthenticatedElement, children } = props

  const { token } = useContext(UserContext)

  // not logged in; hide component
  if (!token && !unAuthenticatedElement) {
    return null
  }

  // not logged in; show fallback
  if (!token && unAuthenticatedElement) {
    return <>{unAuthenticatedElement}</>
  }

  // normal
  return <>{children}</>
}
export default NeedsAuthentication
