/**
 *
 *
 *
 */

import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from "@mui/material"
import { User } from "../../../types/user"
import { useUsers } from "../../hooks/use-users"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useAuthentication from "../../hooks/use-authentication"
import { isValidEmail } from "../../utils/email"


export interface EditUserDialogProps {
  open: boolean
  onClose: () => void
  userId: User['userId'] | undefined
  name: User['name'] | undefined
}


/**
 * @param props
 *
 */
export const EditUserDialog: React.FC<EditUserDialogProps> = ({ open, onClose, userId, name }) => {

  const { t } = useTranslation()

  const { getUser, updateUser, loading } = useUsers()

  const { user: userFromToken } = useAuthentication();

  const [user, setUser] = useState<User | null>(null)

  const [error, setError] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    console.log(name, value);
    setUser(prev => prev ? { ...prev, [name]: value } : null)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setError('')
    if (user) {
      try {
        await updateUser(user)
        onClose()
      } catch (error) {
        console.error(error)
        setError(t('editUserDialog.submitError', { defaultValue: 'Er is een fout opgetreden, tijdens het updaten van de gebruiker.' }))
      }
    }
  }

  useEffect(() => {
    setError('')
    if (userId) {
      getUser(userId)
        .then(user => {
          if( user ) {
            setUser(user)
          } else {
            setUser(null);
            setError(t('editUserDialog.loadUserError', { defaultValue: 'Er is iets fout gegaan tijdens het ophalen van de gebruiker of u heeft geen toegang tot deze gebruiker.' }))
          }
        })
        .catch((err: Error) => {
          console.error(err)
          setError(t('editUserDialog.loadUserError', { defaultValue: 'Er is iets fout gegaan tijdens het ophalen van de gebruiker of u heeft geen toegang tot deze gebruiker.' }))
        })
    }
  }, [userId, getUser, t])


  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-user-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="edit-user-dialog-title">
        {t('editUserDialog.title', { defaultValue: 'Gebruiker bewerken' })}: {name}
      </DialogTitle>

      {loading && <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></DialogContent>}

      {user ? (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                name="name"
                label={t('editUserDialog.name', { defaultValue: 'Naam' })}
                value={user?.name || ''}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                name="email"
                label={t('editUserDialog.email', { defaultValue: 'Email' })}
                value={user?.email || ''}
                onChange={handleChange}
                fullWidth
                required
                error={!isValidEmail(user?.email || '')}
                helperText={!isValidEmail(user?.email || '') ? t('editUserDialog.emailError', { defaultValue: 'U heeft geen geldig email adres ingevuld.' }) : ''}
              />
              <TextField
                name="company"
                label={t('editUserDialog.company', { defaultValue: 'Bedrijf' })}
                value={user?.company || ''}
                onChange={handleChange}
                fullWidth
              />
              {Boolean(userFromToken?.admin) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(user.admin)}
                      onChange={(e, checked) => setUser(prev => prev ? { ...prev, admin: checked } : null)}
                      name="admin"
                    />
                  }
                  label={t('editUserDialog.admin', { defaultValue: 'Geef admin rechten aan deze gebruiker' })}
                />
              )}
            </Box>
            {error && <Alert severity="error" sx={{ marginTop: 1 }}>{error}</Alert>}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="error" variant="outlined" sx={{ margin: 1 }} disabled={loading}>
              {t('editUserDialog.cancel', { defaultValue: 'Annuleren' })}
            </Button>
            <Button type="submit" color="primary" variant="contained" sx={{ margin: 1 }} disabled={loading}>
              {t('editUserDialog.save', { defaultValue: 'Opslaan' })}
            </Button>
          </DialogActions>
        </form>
      ) : (
        <>
          <DialogContent>
            <Alert severity="error">{error}</Alert>
          </DialogContent>
          <DialogActions>
          <Button onClick={onClose} color="error" variant="outlined" sx={{ margin: 1 }} disabled={loading}>
            {t('editUserDialog.cancel', { defaultValue: 'Annuleren' })}
          </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
