/**
 *
 *
 *
 */

import { useTranslation } from "react-i18next"
import useUsers from "../../hooks/use-users"
import { useEffect, useState } from "react"
import { useFilteredSearch } from "../../hooks/use-filtered-search"
import { User } from "../../../types/user"
import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'
import { ConfirmDialog } from "../common/confirm-dialog"
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import UserIcon from '@mui/icons-material/Person'
import { EditUserDialog } from "./edit-users-dialog"
import useAuthentication from "../../hooks/use-authentication"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import NewUserDialog from "./new-user-dialog"

// props
export interface UsersTableProps {}

// component
export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const { t } = useTranslation()

  const { loading, users, listUsers, deleteUser } = useUsers()

  const [search, setSearch] = useState('')

  const { user: userFromToken } = useAuthentication()

  const filteredUsers = useFilteredSearch<User>(users, search)

  const [openNewUserDialog, setOpenNewUserDialog] = useState(false)

  const [openEditUserDialog, setOpenEditUserDialog] = useState<undefined | {
    userId: User['userId'],
    userName: User['name'],
  }>(undefined)

  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState<undefined | {
    userId: User['userId'],
    userName: User['name'],
  }>(undefined)

  const handleOpenNewUserDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpenNewUserDialog(true)
  }

  const handleOpenEditUserDialog = (e: React.MouseEvent<HTMLElement>, user?: User) => {
    e.stopPropagation()
    if( !user ) {
      return;
    }
    setOpenEditUserDialog({
      userId: user.userId,
      userName: user.name,
    })
  }

  const handleDeleteUser = async (userId?: User['userId']) => {
    if( !userId ) {
      return;
    }
    await deleteUser(userId)
    await listUsers()
    setOpenDeleteUserDialog(undefined)
  }

  useEffect(() => {
    if( !users ) {
      listUsers()
    }
  }, [listUsers, users])

  return (
    <>
      <Box
        sx={{
          marginBottom: 1,
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexGrow={1} sx={{marginRight: 4, minWidth: 300}}>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            label={t('userTable.search', {
              defaultValue: 'Zoek gebruiker',
            })}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenNewUserDialog}
        >
          {t('userTable.newUser', {
            defaultValue: 'Nieuwe gebruiker',
          })}
        </Button>
      </Box>

      <NewUserDialog
        open={openNewUserDialog}
        onClose={(reload?: boolean) => {
          setOpenNewUserDialog(false)
          if( reload ) {
            listUsers()
          }
        }}
      />

      <EditUserDialog
        open={Boolean(openEditUserDialog)}
        onClose={() => {
          setOpenEditUserDialog(undefined)
          listUsers()
        }}
        name={openEditUserDialog?.userName}
        userId={openEditUserDialog?.userId}
      />

      <ConfirmDialog
        open={Boolean(openDeleteUserDialog)}
        onCancel={() => setOpenDeleteUserDialog(undefined)}
        onConfirm={() => handleDeleteUser(openDeleteUserDialog?.userId)}
        title={t('userTable.deleteUser', {
          defaultValue: 'Gebruiker verwijderen',
        })}
        text={<>
          { t('userTable.deleteUserDescription', {
            defaultValue: 'Weet u zeker dat u deze gebruiker wilt verwijderen?',
          })}
          <br />
          <br />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography color="text.secondary" fontWeight="bold">
              {openDeleteUserDialog?.userName}
            </Typography>
          </Box>
        </>}
      />


    <TableContainer component={Paper}>
      <Table aria-label="users table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"><UserIcon color="inherit" /></TableCell>
            {userFromToken?.admin && <TableCell padding="checkbox" sx={{ verticalAlign: 'middle', textAlign: 'center' }}>{t('userTable.admin', { defaultValue: 'Admin' })}</TableCell>}
            <TableCell>{t('userTable.name', { defaultValue: 'Naam' })}</TableCell>
            <TableCell>{t('userTable.email', { defaultValue: 'Email' })}</TableCell>
            <TableCell>{t('userTable.company', { defaultValue: 'Bedrijf' })}</TableCell>
            <TableCell>{t('userTable.lastPasswordUpdate', { defaultValue: 'Laatste wachtwoordwijziging' })}</TableCell>
            <TableCell padding="checkbox"><EditIcon color="inherit" /></TableCell>
            <TableCell padding="checkbox"><DeleteIcon color="inherit" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user) => {
            return (
              <TableRow
                key={user.userId}
                hover
                onClick={(e) => handleOpenEditUserDialog(e, user)}
                sx={{ cursor: 'pointer', backgroundColor: (theme) => theme.palette.grey[200], '&:nth-of-type(odd)': { backgroundColor: 'inherit' }}}
              >
                <TableCell padding="checkbox">
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <UserIcon color="primary" />
                  </Box>
                </TableCell>
                { userFromToken?.admin &&
                  <TableCell sx={{ textAlign: 'center' }} padding="checkbox">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {user.admin ? <CheckBoxIcon color="success" /> : <CheckBoxOutlineBlankIcon color="error" />}
                    </Box>
                  </TableCell>
                }
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.company || '-'}</TableCell>
                <TableCell>{user.lastPasswordUpdate ? new Date(user.lastPasswordUpdate).toLocaleString() : '-'}</TableCell>
                <TableCell padding="checkbox">
                  <IconButton color="primary" onClick={(e) => handleOpenEditUserDialog(e, user)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton color="error" onClick={(e) => {
                    e.stopPropagation()
                    setOpenDeleteUserDialog({
                      userId: user.userId,
                      userName: user.name,
                    })
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          {(filteredUsers.length === 0) && (
            <TableRow>
              <TableCell colSpan={8}>
                {search && t('userTable.noUsersFound', {
                  defaultValue: 'Geen gebruikers gevonden',
                })}
                {!search && t('userTable.noUsersAvailable', {
                  defaultValue: 'U heeft nog geen gebruikers gecreerd',
                })}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>

    <Backdrop open={loading} sx={{ position: 'absolute' }} invisible>
      <Box>
        <CircularProgress />
        <Typography
          paragraph
          sx={{ margin: 1, color: 'grey' }}
          variant="caption"
        >
          {t('userTable.loadingUsers', {
            defaultValue: 'Uw gebruikers laden',
          })}
        </Typography>
      </Box>
    </Backdrop>
    </>
  )
}
export default UsersTable
