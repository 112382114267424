/**
 *
 *
 *
 */

import { Backdrop, CircularProgress } from '@mui/material'

export const Loader: React.FC<{ open?: boolean }> = ({ open }) => {
  return (
    <Backdrop open={open ? true : false}>
      <CircularProgress />
    </Backdrop>
  )
}
export default Loader
