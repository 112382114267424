/**
 *
 *
 */

import {
  Box,
  CSSObject,
  Divider,
  IconButton,
  List,
  Theme,
  Toolbar,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import { useContext, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useTranslation } from 'react-i18next'
import NavigationListItem from './navigation-list-item'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import RadioIcon from '@mui/icons-material/Radio'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import PlaceIcon from '@mui/icons-material/Place'
import { UserContext } from '../../contexts/user-context'
import PeopleIcon from '@mui/icons-material/People'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const drawerWidth = 240

// style helpers
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

// helper components
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export interface AppNavigationWrapperProps {
  children: React.ReactNode
}

/**
 * main component
 */
export const AppNavigationWrapper: React.FC<AppNavigationWrapperProps> = (
  props
) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { logout, user } = useContext(UserContext)

  const { children } = props

  const [drawerOpen, setDrawerOpen] = useState(true)

  const handleDrawerOpen = () => setDrawerOpen(true)
  const handleDrawerClose = () => setDrawerOpen(false)

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {t('app.title', { defaultValue: 'AyPlay Dashboard' })}
            {user?.admin ? ' [ADMINISTRATOR]' : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        PaperProps={{ sx: { borderRight: 1 } }}
      >
        <DrawerHeader>
          <Box sx={{ display: 'flex', paddingRight: 3 }}>
            <Typography variant="h4">
              <Box component="strong" sx={{ color: 'primary.main' }}>
                A
              </Box>
              y
              <Box component="strong" sx={{ color: 'primary.main' }}>
                P
              </Box>
              lay
            </Typography>
          </Box>
          <IconButton onClick={handleDrawerClose} color="inherit">
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon color="inherit" />
            ) : (
              <ChevronLeftIcon color="inherit" />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List>
          {/* Overview / Home */}
          <NavigationListItem
            text={t('menu.overview', { defaultValue: 'Overzicht' })}
            drawerOpen={drawerOpen}
            icon={<DashboardIcon />}
            onClick={() => navigate('/')}
            selected={pathname === '/'}
          />

          {/* Music Library */}
          {user?.admin && (
            <NavigationListItem
              text={t('menu.musicLibrary', { defaultValue: 'Muziek Bibliotheek' })}
              drawerOpen={drawerOpen}
              icon={<LibraryMusicIcon />}
              onClick={() => navigate('/music-library')}
              selected={pathname === '/music-library'}
            />
          )}

          {/* Media */}
          <NavigationListItem
            text={t('menu.media', { defaultValue: 'Mijn Media' })}
            drawerOpen={drawerOpen}
            icon={<DriveFolderUploadIcon />}
            onClick={() => navigate('/media')}
            selected={pathname === '/media'}
          />

          {/* Locations */}
          <NavigationListItem
            text={t('menu.locations', { defaultValue: 'Locaties' })}
            drawerOpen={drawerOpen}
            icon={<PlaceIcon />}
            onClick={() => navigate('/locations')}
            selected={pathname === '/locations'}
          />

          {/* Channels */}
          <NavigationListItem
            text={t('menu.channels', { defaultValue: 'Kanalen' })}
            drawerOpen={drawerOpen}
            icon={<SubscriptionsIcon />}
            onClick={() => navigate('/channels')}
            selected={pathname.startsWith('/channels')}
          />

          {/* Players */}
          <NavigationListItem
            text={t('menu.players', { defaultValue: 'Muziekspelers' })}
            drawerOpen={drawerOpen}
            icon={<RadioIcon />}
            onClick={() => navigate('/players')}
            selected={pathname === '/players'}
          />

          {/* Users */}
          <NavigationListItem
            text={t('menu.users', { defaultValue: 'Gebruikers' })}
            drawerOpen={drawerOpen}
            icon={<PeopleIcon />}
            onClick={() => navigate('/users')}
            selected={pathname === '/users'}
          />

          {/* Accounts */}
          <NavigationListItem
            text={t('menu.accounts', { defaultValue: 'Accounts' })}
            drawerOpen={drawerOpen}
            icon={<CorporateFareIcon />}
            onClick={() => navigate('/accounts')}
            selected={pathname === '/accounts'}
          />

          {/* My Profile */}
          <NavigationListItem
            text={t('menu.myProfile', { defaultValue: 'Mijn Profiel' })}
            drawerOpen={drawerOpen}
            icon={<AccountCircleIcon />}
            onClick={() => navigate('/my-profile')}
            selected={pathname === '/my-profile'}
            // subText={(accountId || "shit").toString()}
          />

        </List>

        <Divider color="common.black" />

        <List>

          {/* Logout */}
          <NavigationListItem
            text={t('menu.signOut', { defaultValue: 'Afmelden' })}
            drawerOpen={drawerOpen}
            icon={<LogoutIcon />}
            onClick={() => logout()}
          />
        </List>
      </Drawer>

      {/* Main content container */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}
export default AppNavigationWrapper
