/**
 *
 *
 *
 */

import { Typography } from '@mui/material'

export const PageHeader: React.FC<{ title: string }> = (props) => {
  return <Typography variant="h5" sx={{marginBottom: 4}}>{props.title}</Typography>
}
export default PageHeader
