/**
 *
 *
 *
 */

import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import common_nl from './nl/common.json'
import common_en from './en/common.json'

// setup the language lib we are using
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    lng: 'nl',
    fallbackLng: 'nl',
    load: 'languageOnly',
    resources: {
      nl: {
        common: common_nl,
      },
      en: {
        common: common_en,
      },
    },
  })

export default i18n
