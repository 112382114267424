/**
 *
 *
 *
 */

import { KeyboardEvent } from 'react'

/**
 * detect an enter press and trigger a function
 * @param KeyboardEvent
 * @param callback
 */
export const onEnterPress = (
  e: KeyboardEvent<HTMLDivElement>,
  f: (e: KeyboardEvent<HTMLDivElement>) => void
) => {
  if (e.key === 'Enter' || e.keyCode === 13) {
    f && f(e)
  }
}
