import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Checkbox, FormControlLabel, Alert } from '@mui/material';
import { NewUser } from '../../../types/user';
import useAuthentication from '../../hooks/use-authentication';
import useUsers from '../../hooks/use-users';
import { useTranslation } from 'react-i18next';


interface NewUserDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
}

const NewUserDialog: React.FC<NewUserDialogProps> = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [admin, setAdmin] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuthentication();

  const { t } = useTranslation();

  const { createUser, loading } = useUsers();

  const handleCreate = async () => {
    setError("");
    if (!name || !email) {
      setError(t('newUserDialog.validationError', {
        defaultValue: 'Vul alle velden in',
      }));
      return;
    }
    const newUser: NewUser = { name, email, company, admin };
    try {
      await createUser(newUser);
      onClose(true);
    } catch (error) {
      console.error(error);
      setError(t('newUserDialog.error', {
        defaultValue: 'Er is iets fout gegaan, tijdens het aanmaken van de gebruiker',
      }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('newUserDialog.title', {
          defaultValue: 'Nieuwe gebruiker aanmaken',
        })}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t('newUserDialog.name', {
            defaultValue: 'Naam',
          })}
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label={t('newUserDialog.email', {
            defaultValue: 'Email',
          })}
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label={t('newUserDialog.company', {
            defaultValue: 'Bedrijf',
          })}
          type="text"
          fullWidth
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        {user?.admin && (
          <FormControlLabel
            control={
              <Checkbox
                checked={admin}
                onChange={(e) => setAdmin(e.target.checked)}
                color="primary"
              />
            }
            label={t('newUserDialog.admin', {
              defaultValue: 'Admin',
            })}
          />
        )}
        {error && <Alert severity="error" sx={{ marginTop: 1 }}>{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="error" variant="outlined" sx={{ margin: 1 }}>
          {t('newUserDialog.cancel', {
            defaultValue: 'Annuleren',
          })}
        </Button>
        <Button onClick={handleCreate} color="primary" variant="contained" sx={{ margin: 1 }} disabled={loading}>
          {t('newUserDialog.create', {
            defaultValue: 'Gebruiker aanmaken',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUserDialog;
