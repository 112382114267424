/**
 *
 *
 *
 */

import { Container, Typography } from '@mui/material'
import * as React from 'react'

export const NotFound: React.FC = (props) => {
  return (
    <Container sx={{ textAlign: 'center', paddingTop: '15vh' }}>
      <Typography variant={'h3'}>404 - Not Found</Typography>
    </Container>
  )
}
export default NotFound
