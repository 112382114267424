/**
 *
 */

import { useAuthentication } from '../hooks/use-authentication'
import { createContext } from 'react'

export const UserContext = createContext<ReturnType<typeof useAuthentication>>({
  token: undefined,
  accountId: undefined,
  setAccountId: (i) => {},
  accounts: undefined,
  user: undefined,
  loading: false,
  login: async (u: string, p: string) => false,
  logout: async () => false,
  isTokenValid: async () => false,
  switchAccount: async (newAccountId?: number) => false,
})

export interface UserProviderProps {
  children: React.ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = (props) => {
  const { children } = props

  const userHook = useAuthentication()

  return (
    <UserContext.Provider value={userHook}>{children}</UserContext.Provider>
  )
}
