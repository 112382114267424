/**
 *
 */

import { useCallback } from 'react'

// use sessionStorage hook
export const useSessionStorage = () => {
  // test if sessionStorage is available
  const sessionStorageAvailable: () => boolean = () => {
    try {
      sessionStorage.setItem('test', 'test')
      sessionStorage.removeItem('test')
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  // load from sessionStorage
  const load = useCallback((key: string, fallback?: string) => {
    if (sessionStorageAvailable()) {
      try {
        return sessionStorage.getItem(key) || undefined
      } catch (e) {
        console.error(e)
        return fallback || undefined
      }
    }
    return fallback || undefined
  }, [])

  // save to sessionStorage
  const save = useCallback((key: string, value: string) => {
    if (sessionStorageAvailable()) {
      try {
        sessionStorage.setItem(key, value)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
    return false
  }, [])

  // remove from sessionStorage
  const remove = useCallback((key: string) => {
    if (sessionStorageAvailable()) {
      try {
        sessionStorage.removeItem(key)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
    return false
  }, [])

  // make functions available
  return {
    load,
    save,
    remove,
  }
}

export default useSessionStorage
