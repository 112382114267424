/**
 *
 */

import {
  Alert,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useEffect, useState } from 'react'
import { UserContext } from '../../contexts/user-context'
import { onEnterPress } from '../../utils/keyboard'
import { Account } from '../../../types/account'

export interface LoginFormProps {
  onSuccess?: () => void
  onCancel?: () => void
  disableCancelButton?: boolean
}

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { onSuccess, onCancel, disableCancelButton } = props

  const { t } = useTranslation('common')

  const { login, loading, accounts, setAccountId, accountId } = useContext(UserContext)
  const [msg, setMsg] = useState<string | undefined>(undefined)

  const [status, setStatus] = useState<'login'|'select-account'>("login")

  const [email, setEmail] = useState<string>('')
  const [pw, setPw] = useState<string>('')

  const handleLogin = useCallback(async () => {
    setMsg('')
    const success = await login(email, pw, accountId)
    if (!success) {
      setMsg(
        t('loginForm.authenticationError', {
          defaultValue: 'Het inloggen is mislukt, controleer uw gegevens',
        })
      )
      return;
    }
    onSuccess && onSuccess()
  }, [onSuccess, email, pw, accountId, login, t])

  const handleAccountSelection = useCallback((id: Account['accountId']) => {
    setAccountId(id);
  }, [setAccountId])

  const handleCancel = useCallback(() => {
    onCancel && onCancel()
  }, [onCancel])

  useEffect(() => {
    if (accounts) {
      setStatus("select-account");
    } else {
      setStatus("login")
    }
  }, [accounts])

  return (
    <Box sx={{ width: '100%', padding: 4 }} component={Paper}>
      <Stack spacing={2}>
        <Box sx={{ textAlign: 'center', paddingBottom: 1 }}>
          <Typography variant="h3">
            <Box component="strong" sx={{ color: 'primary.main' }}>
              A
            </Box>
            y
            <Box component="strong" sx={{ color: 'primary.main' }}>
              P
            </Box>
            lay
          </Typography>
        </Box>


        { status === "login" && (
          <>
            <Box sx={{ textAlign: 'center', padding: 1 }}>
              <Typography variant="subtitle1">
                {t('loginForm.subtitle', {
                  defaultValue:
                    'Voer uw e-mailadres en wachtwoord in om u aan te melden.',
                })}
              </Typography>
            </Box>

            <TextField
              fullWidth
              type="email"
              label={t('loginForm.email', { defaultValue: 'E-mailadres' })}
              sx={{ margin: 1 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={(e) => onEnterPress(e, handleLogin)}
            />

            <TextField
              fullWidth
              type="password"
              label={t('loginForm.password', { defaultValue: 'Wachtwoord' })}
              sx={{ margin: 1 }}
              value={pw}
              onChange={(e) => setPw(e.target.value)}
              onKeyUp={(e) => onEnterPress(e, handleLogin)}
            />
          </>
        )}

        { status === "select-account" && (
          <>
          <Box sx={{ textAlign: 'center', padding: 1 }}>
            <Typography variant="subtitle1">
              {t('loginForm.subtitle', {
                defaultValue:
                  'Kies het account waaronder u wilt werken. Klik daarna op aanmelden',
              })}
            </Typography>
          </Box>
          <List>
            { accounts?.map( acnt => (
              <ListItemButton
                key={acnt.accountId}
                sx={{
                  minHeight: 60,
                  outline: acnt.accountId === accountId ? 1 : undefined,
                  borderColor: 'primary.main',
                  borderRadius: 1,
                  marginBottom: 0.5,
                }}
                selected={acnt.accountId === accountId}
                onClick={() =>handleAccountSelection(acnt.accountId)}
              >
                <ListItemIcon>
                  <CorporateFareIcon
                    // htmlColor={acnt.accountId === accountId ? 'orange' : undefined}
                    color={acnt.accountId === accountId ? 'primary' : undefined}
                  />
                </ListItemIcon>
                <ListItemText>
                  {acnt.name}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
          </>
        )}

        {msg && (
          <Alert severity="error" variant="filled">
            {msg}
          </Alert>
        )}
        <Box sx={{ textAlign: 'right', paddingTop: 2 }}>
          {!disableCancelButton && (
            <Button
              onClick={handleCancel}
              variant="outlined"
              sx={{ marginRight: 1 }}
              disabled={loading}
            >
              {t('loginForm.cancelButton', { defaultValue: 'Annuleren' })}
            </Button>
          )}

          <Button onClick={handleLogin} variant="contained" disabled={loading}>
            {t('loginForm.loginButton', { defaultValue: 'Aanmelden' })}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
export default LoginForm
