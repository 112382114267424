import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
  open: boolean;
  text?: React.ReactNode;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, text, title, confirmText, cancelText, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">
        {title || t('confirmDialog.title', { defaultValue: 'Bevestiging' })}
      </DialogTitle>
      <DialogContent>
        {text || t('confirmDialog.text', { defaultValue: 'Weet u zeker dat u dit wilt doen?' })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="error" variant="outlined" sx={{ margin: 1 }}>
          {cancelText || t('confirmDialog.cancel', { defaultValue: 'Annuleren' })}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus variant="contained" sx={{ margin: 1 }}>
          {confirmText || t('confirmDialog.confirm', { defaultValue: 'Bevestigen' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
