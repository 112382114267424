/**
 *
 *
 *
 */

import { Container } from "@mui/material"
import { useTranslation } from "react-i18next"
import { PageHeader } from "../components/common/page-header"
import UsersTable from "../components/users/users-table"

// props
export interface UsersProps {}


// component
export const Users: React.FC<UsersProps> = (props) => {

  const { t } = useTranslation()

  return (
    <Container  sx={{ textAlign: 'center', paddingTop: '2vh' }} maxWidth="xl">
      <PageHeader title={t('users.title', { defaultValue: 'Gebruikers' })} />
      <UsersTable />
    </Container>
  )
}
export default Users
