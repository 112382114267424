/**
 *
 *
 *
 */

/**
 *  Auth Header used in HTTP requests
 */
export const tokenHeaderName = 'X-AyPlay-Token'

/**
 * session storage key name for the token
 */

export const tokenKey = 'ayplay-token'

/**
 * key for storing the session
 */

export const sessionKey = 'ayplay-session'
