/**
 *
 *
 *
 */

import { useCallback, useState } from "react"
import { User, UpdatedUser, NewUser } from "../../types/user"
import { useFetch } from "./use-fetch"

export const useUsers = () => {

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<User[] | undefined>(undefined)

  const { requestApi } = useFetch()

  /**
   * @returns the users or false on failure
   */
  const listUsers = useCallback(async () => {
    try {
      setLoading(true)
      const response = await requestApi('/users', { method: 'GET' })
      if( response && response.users) {
        setUsers(response.users)
      } else {
        setUsers([])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [requestApi])

  /**
   * @param userId
   * @returns the user or false on failure
   */
  const getUser = useCallback(async (userId: User['userId']) => {
    try {
      setLoading(true)
      const response = await requestApi(`/users/${userId}`, { method: 'GET' })
      if( response) {
        setLoading(false)
        return response as User
      }
      setLoading(false)
      return false
    } catch (error) {
      console.error(error)
      setLoading(false)
      return false
    }
  }, [requestApi])

  /**
   * @param newUser
   * @returns the created user or false on failure
   */
  const createUser = useCallback(async (newUser: NewUser) => {
    if(!newUser || !newUser.name || !newUser.email) {
      return false
    }
    try {
      setLoading(true)
      await requestApi('/users', { method: 'PUT', body: JSON.stringify(newUser) })
      setLoading(false)
      return true
    } catch (error) {
      console.error(error)
      setLoading(false)
      return false
    }
  }, [requestApi])

  /**
   * @param updatedUser
   * @returns boolean success or not
   */
  const updateUser = useCallback(async (updatedUser: UpdatedUser) => {
    if(!updatedUser || !updatedUser.userId || !updatedUser.name || !updatedUser.email) {
      return false
    }
    try {
      setLoading(true)
      await requestApi(`/users/${updatedUser.userId}`, { method: 'PATCH', body: JSON.stringify(updatedUser) })
      setLoading(false)
      return true
    } catch (error) {
      console.error(error)
      setLoading(false)
      return false
    }
  }, [requestApi])

  /**
   * @param userId
   * @returns boolean success or not
   */
  const deleteUser = useCallback(async (userId: User['userId']) => {
    try {
      setLoading(true)
      await requestApi(`/users/${userId}`, { method: 'DELETE' })
      setLoading(false)
      return true
    } catch (error) {
      setLoading(false)
      console.error(error)
      return false
    }
  }, [requestApi])

  return {
    loading,
    users,
    listUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser
  }
}
export default useUsers