/**
 *
 *
 *
 */

/**
 * set the API base url
 */
const getBaseUrl = () => {
  let baseUrl = `/api/v1`

  // for testing/developing, so i can run a `bun run start` whilest the backend is also running and hot-updating :)
  if (window && window.location.hostname === 'localhost') {
    baseUrl = 'http://localhost:8788/api/v1'
  }
  return baseUrl
}
export const apiBaseUrl = getBaseUrl()
