/**
 *
 *
 *
 */

import { useMemo } from "react"

/**
 *
 * @param inputs any js object will be stringified and filtered by search
 * @returns
 */
export const useFilteredSearch = <filterType>(inputs?: filterType[], search?: string) => {
  return useMemo(() => {
    if( !inputs ) return [] as filterType[]
    if( !search ) return inputs
    return inputs.filter( input => {
      const jsonString = JSON.stringify(input)
      return jsonString.toLowerCase().includes(search.toLowerCase())
    }) as filterType[]
  }, [inputs, search])
}