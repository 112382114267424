/**
 *
 */

import { Grid } from '@mui/material'
import LoginForm from './login-form'

export interface LoginProps {}

export const Login: React.FC<LoginProps> = (props) => {
  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ minHeight: '100vh' }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        md={2}
        sx={{
          boxShadow: 3,
          borderRadius: 1,
          border: 1,
          marginTop: 2,
          maxWidth: 513,
        }}
      >
        <LoginForm disableCancelButton />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: '20vh' }} />
    </Grid>
  )
}
export default Login
