/*
 *
 *
 **/

import { useCallback, useContext } from 'react'
import { UserContext } from '../contexts/user-context'
import { tokenHeaderName } from '../config/authentication'
import { apiBaseUrl } from '../config/api'

export const useFetch = () => {
  const { token } = useContext(UserContext)

  const requestApi = useCallback(
    async <GivenType = Record<any, any>>(url: string, options: RequestInit) => {
      if (token) {
        options.headers = {
          'Content-Type': 'application/json',
          [tokenHeaderName]: `${token}`,
          ...options.headers,
        }
      }

      // add starting slash if missing
      if (url.startsWith('/') === false) {
        url = `/${url}`
      }

      const fullUrl = `${apiBaseUrl}${url}`

      const response = await fetch(fullUrl, options)

      if (!response.ok) {
        if (response.body) {
          const msg = await response.json()
          throw new Error(`${msg}`)
        } else {
          throw new Error(`requestApi non 200 response: ${response.status}`)
        }
      }

      // 204 returns nothing
      if (response.status === 204) {
        return
      }

      const json = await response.json()

      return json as GivenType
    },
    [token]
  )

  const uploadFile = useCallback(async <GivenType = Record<any, any>>(url: string, file: File) => {
    if( !url ) {
      return
    }

    // add starting slash if missing
    if (url.startsWith('/') === false) {
      url = `/${url}`
    }

    const fullUrl = `${apiBaseUrl}${url}`

    const formData = new FormData()
    formData.append('file', file)
    const response = await fetch(
      fullUrl,
      {
        method: 'PUT',
        body: formData,
        headers: {
          [tokenHeaderName]: `${token}`,
        }
      }
    )

    if(!response.ok) {
      throw new Error(`uploadFile non 200 response: ${response.status}`)
    }

    const json = await response.json()

    return json as GivenType
  }, [token])

  return {
    requestApi,
    uploadFile,
  }
}

export default useFetch
