/**
 *
 *
 *
 */

import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#13bbbb',
    },
    secondary: {
      main: '#BB1313',
    },
  },
})
